module.exports = {
  "editSavedViewAdmin": {
    "name": "edit saved view",
    "class": "usage",
    "properties": {
      "action": "string",
      "modified": {
        "type": "boolean",
        "isOptional": true
      },
      "isOwner": {
        "type": "boolean",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "useLocalSearch": {
    "name": "use local search",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "movedBoardColumnItem": {
    "name": "moved board column item",
    "class": "usage",
    "properties": {
      "type": {
        "type": "string"
      },
      "objectTypeId": {
        "type": "string"
      },
      "stageType": {
        "type": "string",
        "isOptional": true
      },
      "stageName": {
        "type": "string"
      },
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "openSavedView": {
    "name": "open saved view",
    "class": "interaction",
    "properties": {
      "action": "string",
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "editColumns": {
    "name": "edit columns",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string"
      },
      "objectTypeId": {
        "type": "string"
      },
      "objectTypeName": {
        "type": "string"
      },
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "inlineEditing": {
    "name": "inline editing",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "save",
          "cancel",
          "shown unsaved changes modal",
          "navigated away with unsaved changes",
          "canceled unsaved changes modal",
          "confirmed unsaved changes modal"
        ]
      },
      "objectTypeId": {
        "type": "string"
      },
      "numberOfProperties": {
        "type": "number",
        "isOptional": true
      },
      "totalRecords": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "indexPageview": {
    "name": "index pageview",
    "class": "view",
    "properties": {
      "viewType": {
        "type": "string"
      },
      "objectTypeId": {
        "type": "string"
      },
      "objectTypeName": {
        "type": "string"
      },
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "numberOfPinnedViews": {
        "type": "number",
        "isOptional": true
      },
      "pinnedViewsLimit": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "crm",
    "version": "1"
  },
  "indexInteractions": {
    "name": "index interactions",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "objectId": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeName": {
        "type": "string",
        "isOptional": true
      },
      "toObjectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "isInactive": {
        "type": "boolean",
        "isOptional": true
      },
      "hasDealPriority": {
        "type": "boolean",
        "isOptional": true
      },
      "dealPriority": {
        "type": "string",
        "isOptional": true
      },
      "numberOfPinnedViews": {
        "type": "number",
        "isOptional": true
      },
      "pipelineId": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "bulkAssign": {
    "name": "bulk assign",
    "class": "usage",
    "namespace": "crm",
    "version": "1",
    "properties": {
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    }
  },
  "filterInteractions": {
    "name": "filter interactions",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "component": {
        "type": "string",
        "isOptional": true
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "condition": {
        "type": "string",
        "isOptional": true
      },
      "filterFamily": {
        "type": "string",
        "isOptional": true
      },
      "panelKey": {
        "type": "string",
        "isOptional": true
      },
      "maxCount": {
        "type": "number",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "editSavedView": {
    "name": "edit saved view",
    "class": "usage",
    "properties": {
      "action": "string",
      "modified": {
        "type": "boolean",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "createSavedView": {
    "name": "create saved view",
    "class": "usage",
    "properties": {
      "action": "string",
      "fromClone": {
        "type": "boolean",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "bulkEditRecords": {
    "name": "bulk edit records",
    "class": "usage",
    "properties": {
      "subscreen2": {
        "type": "string"
      },
      "totalCount": {
        "type": "string"
      },
      "editedProperties": {
        "type": "string"
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "bulkDelete": {
    "name": "bulk delete",
    "class": "usage",
    "properties": {
      "subscreen2": {
        "type": "string"
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "use-bulk-enroll-in-sequence": {
    "name": "use-bulk-enroll-in-sequence",
    "class": "usage",
    "namespace": "crm",
    "version": "1",
    "properties": {
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    }
  },
  "saveBulkCommunicationSubscription": {
    "name": "save bulk communication subscription",
    "class": "usage",
    "properties": {
      "step": {
        "type": [
          "MODAL_OPEN",
          "FINISH_SUCCESS",
          "FINISH_FAILURE"
        ],
        "isOptional": true
      },
      "channel": {
        "type": [
          "EMAIL",
          "WHATSAPP",
          "SMS"
        ],
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "legalBasis": {
        "type": [
          "LEGITIMATE_INTEREST_PQL",
          "LEGITIMATE_INTEREST_CLIENT",
          "PERFORMANCE_OF_CONTRACT",
          "CONSENT_WITH_NOTICE",
          "NON_GDPR",
          "PROCESS_AND_STORE",
          "LEGITIMATE_INTEREST_OTHER"
        ],
        "isOptional": true
      },
      "removeFromAllEmail": {
        "type": "boolean",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "use-bulk-enroll-in-workflow": {
    "name": "use-bulk-enroll-in-workflow",
    "class": "usage",
    "namespace": "crm",
    "version": "1",
    "properties": {
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    }
  },
  "recordInteractions": {
    "name": "record interactions",
    "class": "interaction",
    "properties": {
      "action": "string",
      "objectType": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "toObjectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "fromObjectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "record-interaction": {
    "name": "record-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "label": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "step": {
        "type": "string",
        "isOptional": true
      },
      "isRecommended": {
        "type": "boolean",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "createRecord": {
    "name": "create record",
    "class": "usage",
    "properties": {
      "type": "string",
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "pageRefresh": {
    "name": "refresh",
    "class": "interaction",
    "properties": {
      "objectType": {
        "type": "string"
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "index-interaction": {
    "name": "index-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "bizops-interaction": {
    "name": "bizops-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "crm-onboarding": {
    "name": "crm-onboarding",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "link": {
        "type": "string",
        "isOptional": true
      },
      "text": {
        "type": "string",
        "isOptional": true
      },
      "experiment": {
        "type": "string",
        "isOptional": true
      },
      "step": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "bulkAddToList": {
    "namespace": "lists",
    "name": "bulk add to list",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": [
        "index"
      ],
      "subscreen2": [
        "bulk-list-modal"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "version": "1"
  },
  "productPaymentLinkModalInteraction": {
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "View modal",
        "Create Payment Link"
      ],
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "version": "1"
  },
  "productPanelUsage": {
    "namespace": "products",
    "name": "Product panel",
    "class": "usage",
    "properties": {
      "action": [
        "Create product",
        "Edit product"
      ],
      "subAction": [
        "Save",
        "Save and add another"
      ],
      "propertyName": {
        "type": "array"
      },
      "billingFrequency": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "version": "1"
  },
  "marketingEventsOptInModal": {
    "name": "marketing events opt in modal",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "object added",
          "object rejected"
        ]
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "recordUsage": {
    "name": "record interaction",
    "class": "usage",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "what_value": {
        "type": "number",
        "isOptional": true
      },
      "property_name": {
        "type": "string",
        "isOptional": true
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "crm",
    "version": "1"
  },
  "ticketsActivation": {
    "name": "tickets activation",
    "class": "activation",
    "namespace": "crm",
    "version": "1",
    "properties": {
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": "string"
    }
  },
  "clickAssetViewDetails": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "asset-action"
      ]
    },
    "namespace": "reporting"
  },
  "clickCreateAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickGoToDashboard": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "navigate"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "go-to-dashboard"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard"
        ],
        "isOptional": false
      },
      "destination": {
        "type": [
          "existing-dashboard"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "saveNewAssetInteraction": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "save-new"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "start",
          "exit"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": [
          "save-from-template",
          "save-from-scratch",
          "save-from-drilldown",
          "save-updates-as-new",
          "clone",
          "ai-builder-generated-report",
          "save-from-index",
          "save-from-crm-ai-insights"
        ],
        "isOptional": false
      },
      "whichStage": {
        "type": [
          "report-name-and-destination",
          "assignment-mode",
          "specific-partitioning"
        ],
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "saveNewAsset": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "save-new"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "complete"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": [
          "save-from-template",
          "save-from-scratch",
          "save-from-drilldown",
          "save-updates-as-new",
          "clone",
          "report-destination",
          "ai-builder-generated-report",
          "save-from-index",
          "save-from-crm-ai-insights"
        ],
        "isOptional": false
      },
      "destination": {
        "type": [
          "new-dashboard",
          "existing-dashboard",
          "none"
        ],
        "isOptional": true
      },
      "assignmentMode": {
        "type": [
          "everyone",
          "specific",
          "private"
        ],
        "isOptional": true
      },
      "permissionValue": {
        "type": [
          "none",
          "view",
          "edit"
        ],
        "isOptional": true
      },
      "teamCount": {
        "type": "number",
        "isOptional": true
      },
      "userCount": {
        "type": "number",
        "isOptional": true
      },
      "withGeneratedDescription": {
        "type": "boolean",
        "isOptional": true
      },
      "wasGeneratedDescriptionCustomized": {
        "type": "boolean",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickSaveAssetSuccessLink": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "navigate"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "go-to-saved-asset"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": [
          "save-from-template",
          "save-from-scratch",
          "save-from-drilldown",
          "save-updates-as-new",
          "clone",
          "ai-builder-generated-report",
          "save-from-index",
          "save-from-crm-ai-insights"
        ],
        "isOptional": false
      },
      "destination": {
        "type": [
          "new-dashboard",
          "existing-dashboard",
          "none"
        ],
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickCreateCustomReport": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "create",
          "create-from-template"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": [
          "save-from-template",
          "save-from-scratch",
          "ai-builder-generated-report",
          "save-from-index",
          "save-from-crm-ai-insights"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "report-insights"
        ],
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "templateKey": {
        "type": "string",
        "isOptional": true
      },
      "step": {
        "type": [
          "customize-template"
        ],
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickRestoreAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ]
      },
      "action": {
        "type": [
          "restore"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickDeleteAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "delete"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickSetBusinessUnitNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "tag"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page",
          "actions-dropdown"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "asset-action"
      ],
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "setBusinessUnitNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "save"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "tag"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "interactionWith": [
        "modal"
      ],
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "restoreAsset": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "save"
        ]
      },
      "action": {
        "type": [
          "restore"
        ]
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "isAdmin": {
        "isOptional": false,
        "type": "boolean"
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "deleteAsset": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "delete"
        ]
      },
      "action": {
        "type": [
          "delete"
        ]
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickShareAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "share"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "email",
          "share-asset",
          "export"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown",
          "widget"
        ],
        "isOptional": false
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "selectedReport": {
        "type": [
          "main-report",
          "summarized-table",
          "unsummarized-table"
        ],
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickCopyReportUrl": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "share"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "copy-report-url"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": false
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickManageRecurringShareAsset": {
    "class": "interaction",
    "properties": {
      "object": {
        "type": [
          "email",
          "share-asset"
        ]
      },
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "open"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown",
          "widget"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "sendReportShareAssetNow": {
    "class": "interaction",
    "properties": {
      "object": {
        "type": [
          "email",
          "slack"
        ]
      },
      "actionType": {
        "type": [
          "share"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "send-email",
          "send-slack"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "dialog"
        ],
        "isOptional": false
      },
      "externalEmailDomains": {
        "type": "string",
        "isOptional": true
      },
      "exportType": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "createRecurringShareAssets": {
    "class": "interaction",
    "properties": {
      "object": {
        "type": [
          "email",
          "slack"
        ]
      },
      "actionType": {
        "type": [
          "share"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "create-recurring-email",
          "create-recurring-slack"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "dialog"
        ],
        "isOptional": false
      },
      "exportType": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "startEditRecurringShareAssetFlow": {
    "class": "interaction",
    "properties": {
      "object": {
        "type": [
          "email",
          "slack"
        ]
      },
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "change-owner",
          "delete",
          "edit-schedule"
        ]
      },
      "interactionSource": {
        "type": [
          "actions-dropdown",
          "dialog"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "updateSavedRecurringShareAsset": {
    "class": "usage",
    "properties": {
      "object": {
        "type": [
          "email",
          "slack"
        ]
      },
      "actionType": {
        "type": [
          "save"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "change-owner",
          "delete",
          "edit-schedule"
        ]
      },
      "interactionSource": {
        "type": [
          "actions-dropdown",
          "dialog"
        ],
        "isOptional": false
      },
      "exportType": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickPreviewShareAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "view"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "open",
          "export"
        ],
        "isOptional": false
      },
      "object": {
        "type": [
          "email",
          "slack",
          "report"
        ]
      },
      "interactionSource": {
        "type": [
          "dialog",
          "actions-dropdown",
          "widget"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickEditInBuilder": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit-in-builder"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickGoToCrm": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "description"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "go-to-crm"
        ],
        "isOptional": false
      },
      "dataType": {
        "type": "string"
      },
      "interactionSource": {
        "type": [
          "reports-list",
          "dashboard"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickGoToDatasets": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "navigate"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "go-to-datasets"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickUpdateAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit",
          "change-owner",
          "rename",
          "editDescription"
        ],
        "isOptional": false
      },
      "object": {
        "type": [
          "name",
          "owner",
          "business unit",
          "config",
          "filters",
          "description"
        ]
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "completeAssetUpdate": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "save"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit",
          "change-owner",
          "rename"
        ],
        "isOptional": false
      },
      "object": {
        "type": [
          "name",
          "owner",
          "description",
          "business unit",
          "config",
          "filters",
          "layout"
        ]
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "withGeneratedDescription": {
        "type": "boolean",
        "isOptional": true
      },
      "wasGeneratedDescriptionCustomized": {
        "type": "boolean",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "cancelAssetUpdate": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "close"
        ],
        "isOptional": false
      },
      "object": {
        "type": [
          "name",
          "owner",
          "description",
          "business unit",
          "config",
          "filters"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickGenerateReportDescription": {
    "class": "interaction",
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1",
    "properties": {
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "clickRateGeneratedReportDescription": {
    "class": "interaction",
    "properties": {
      "rating": {
        "type": [
          "THUMBS_UP",
          "THUMBS_DOWN"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "editAssetAccessInteraction": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit-access"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "start",
          "exit"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "completeEditAssetAccess": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit-access"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "complete"
        ],
        "isOptional": false
      },
      "assignmentMode": {
        "type": [
          "everyone",
          "specific",
          "private"
        ],
        "isOptional": true
      },
      "permissionValue": {
        "type": [
          "none",
          "view",
          "edit"
        ],
        "isOptional": true
      },
      "teamCount": {
        "type": "number",
        "isOptional": true
      },
      "userCount": {
        "type": "number",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickRefreshAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "view"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "refresh"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "report-viewer",
          "widget"
        ],
        "isOptional": false
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "openAsset": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "view"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "open"
        ],
        "isOptional": false
      },
      "aiInsightsAvailable": {
        "type": "boolean",
        "isOptional": true
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "addToDashboardInteraction": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "add-to-dashboard"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "start",
          "exit"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "completeAddToDashboard": {
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "add-to-dashboard"
        ],
        "isOptional": false
      },
      "step": {
        "type": [
          "complete"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickDeleteActionNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1",
    "properties": {
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "clickSetDefaultDashboard": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "delete"
        ]
      },
      "step": [
        "start"
      ],
      "assetType": [
        "dashboard"
      ],
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "deleteDashboardNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "action": {
        "type": [
          "edit-default-dashboard"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "setDefaultDashboardNew": {
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "removeDefaultDashboardNew": {
    "class": "interaction",
    "properties": {
      "subscreen": [
        "dashboard",
        "dashboard-manager"
      ],
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "updateFavoriteAssetsNew": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "add-favorite",
          "remove-favorite"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "location": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickMoveReportNew": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "move-report-to-dashboard"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "dashboard"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "onConfirmMoveReport": {
    "class": "interaction",
    "properties": {
      "use": {
        "type": "string",
        "isOptional": true
      },
      "dashboardType": {
        "type": [
          "NEW",
          "EXISTING"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "goToCrm": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "navigate"
        ]
      },
      "action": {
        "type": [
          "go-to-crm"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickAccountSettingExternalEmailToolTipLink": {
    "class": "interaction",
    "properties": {
      "actionType": [
        "click"
      ],
      "action": {
        "type": [
          "Navigate-to-account-settings-page",
          "Navigate-to-learn-more-about-connecting-verified-domains"
        ]
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ]
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "createReportInBuilder": {
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ]
      },
      "action": {
        "type": [
          "create-report"
        ]
      },
      "interactionSource": {
        "type": "string",
        "isOptional": false
      },
      "objectType": {
        "type": "string",
        "isOptional": false
      },
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "eventName": {
        "type": [
          "createReportInBuilder"
        ]
      },
      "whichBuilder": {
        "type": [
          "SORB",
          "CRB"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "dashboard",
          "ai-generated-report"
        ],
        "isOptional": false
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickCopyDashboardUrlNew": {
    "class": "interaction",
    "properties": {
      "actionType": [
        "click"
      ],
      "assetType": {
        "type": [
          "dashboard"
        ]
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "name": "ANALYTICS_INTERACTION",
    "version": "1"
  },
  "clickAddSectionHeaderToDashboardButton": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "click-add-section-header-to-dashboard-button"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ]
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "completeAddSectionHeaderToDashboardButton": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "create"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "complete-add-section-header-to-dashboard"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard"
        ]
      },
      "interactionSource": {
        "type": [
          "actions-dropdown"
        ],
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "isDatasetsReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "template": {
        "type": "string",
        "isOptional": true
      },
      "role": {
        "type": [
          "viewer",
          "editor",
          "owner",
          "admin"
        ],
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "accountingIntegrationsUsage": {
    "name": "accountingIntegrationsUsage",
    "properties": {
      "action": [
        "dismissed invoice sync error banner",
        "navigate to invoice sync failure page"
      ]
    },
    "namespace": "accounting-integrations-ui-components",
    "class": "usage",
    "version": "1"
  },
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "prompt": {
        "type": "string",
        "isOptional": true
      },
      "hasPrefillValues": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "copyMessageInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  }
};